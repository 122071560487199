import { Link } from 'react-router-dom'
import React from 'react'
import story1 from '../../../assets/images/story01.png'
import story2 from '../../../assets/images/story02.png'
import story3 from '../../../assets/images/story03.png'

const Main = () => {
  return (
    <>
        <section className="row_am latest_story" id="blog">
            <div className="container">
                <div className="section_title" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                    <h2>Continuación Soluciones <span>Tecnológicas</span></h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typese tting <br/> indus orem Ipsum has beenthe standard dummy.</p>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="story_box" data-aos="fade-up" data-aos-duration="1500">
                            <div className="story_img">
                            <img src={story1} alt="image" />
                            {/* <span>45 min ago</span> */}
                            </div>
                            <div className="story_text">
                                <h3>Integración de múltiples softwares</h3>
                                <p>Maximiza tus oportunidades de éxito con nuestra solución de integración de múltiples softwares. 
                                    Unifica tus procesos y optimiza tus operaciones para alcanzar un rendimiento óptimo en el mercado de apuestas en línea</p>
                                {/* <Link to="/blog-single">READ MORE</Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="story_box" data-aos="fade-up" data-aos-duration="1500">
                            <div className="story_img">
                            <img src={story2} alt="image" />
                            {/* <span>45 min ago</span> */}
                            </div>
                            <div className="story_text">
                                <h3>Desarrollo de sitios de apuestas online</h3>
                                <p>Ofrece a tus usuarios una  experiencia de juego en línea única con nuestro desarrollo de sitios de apuestas en línea. Aprovecha la tecnología más avanzada y 
                                garantiza la satisfacción de tus 
                                clientes.</p>
                                {/* <Link to="/blog-single">READ MORE</Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="story_box" data-aos="fade-up" data-aos-duration="1500">
                            <div className="story_img">
                            <img src={story3} alt="image" />
                            {/* <span>45 min ago</span> */}
                            </div>
                            <div className="story_text">
                                <h3>Integración con servicios complementarios</h3>
                                <p>Aumenta la rentabilidad de tus operaciones con nuestra solución de integración con servicios complementarios. Oferece a tus usuarios una experiencia de juego completa y mejora la satisfacción de tus clientes.</p>
                                {/* <Link to="/blog-single">READ MORE</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    </>
  )
}

export default Main