import React from 'react'
import img1 from '../../../assets/images/secure_data.png'
import img2 from '../../../assets/images/functional.png'
import img3 from '../../../assets/images/live-chat.png'
import img4 from '../../../assets/images/support.png'
import img5 from '../../../assets/images/features_frame.png'

const Main = ({video}) => {
  return (
    <>
        {video ?
        <section className="row_am features_section video-features" id="features">
            <div className="container">
                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                <h2>Vive la <span>Experiencia 360</span> </h2>
                <p>¿Quieres unir tus servicios de casino físico y en línea en una sola 
                experiencia para tus clientes? Con Mixer, es posible. 
                Nuestra oferta te permite integrar además Hoteles, Restaurantes, 
                Eventos y Club de socios, creando una experiencia completa en entretenimiento. 
                ¡No pierdas esta oportunidad y unifica tus servicios hoy mismo!</p>
                </div>
                <div className="feature_detail">
                <div className="left_data feature_box">
                    <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                    <div className="icon">
                        <img src="assets/images/secure.png" alt="image" />
                    </div>
                    <div className="text">
                        <h4>PROVEDORES DE CONTENIDO</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing and type setting indus ideas.</p>
                    </div>
                    </div>
                    <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                    <div className="icon">
                        <img src="assets/images/abt_functional.png" alt="image" />
                    </div>
                    <div className="text">
                        <h4>JUEGOS DE CASINO</h4>
                        <p>Simply dummy text of the printing and typesetting indus lorem Ipsum is dummy.</p>
                    </div>
                    </div>
                </div>
                <div className="right_data feature_box">
                    <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                    <div className="icon">
                        <img src="assets/images/communication.png" alt="image"/>
                    </div>
                    <div className="text">
                        <h4>Live chat</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing and type setting indus ideas.</p>
                    </div>
                    </div>
                    <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                    <div className="icon">
                        <img src="assets/images/abt_support.png" alt="image" />
                    </div>
                    <div className="text">
                        <h4>24-7 Support</h4>
                        <p>Simply dummy text of the printing and typesetting indus lorem Ipsum is dummy.</p>
                    </div>
                    </div>
                </div>
                <div className="feature_img" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                    <img src="assets/images/features_frame.png" alt="image" />
                </div>
                </div>
            </div>
        </section>
        :
        <section className="row_am features_section" id="features">
            <div className="container">
                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                <h2>Vive la <span>Experiencia 360</span> </h2>
                <p>¿Quieres unir tus servicios de casino físico y en línea en una sola 
                experiencia para tus clientes? Con Mixer, es posible. 
                Nuestra oferta te permite integrar además Hoteles, Restaurantes, 
                Eventos y Club de socios, creando una experiencia completa en entretenimiento. 
                ¡No pierdas esta oportunidad y unifica tus servicios hoy mismo!</p>
                </div>
                <div className="feature_detail">
                <div className="left_data feature_box">
                    <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                    <div className="icon">
                        <img src={img1} alt="image" />
                    </div>
                    <div className="text">
                        <h4>+50</h4>
                        <h4>PROVEDORES DE CONTENIDO</h4>
                    </div>
                    </div>
                    <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                    <div className="icon">
                        <img src={img2} alt="image" />
                    </div>
                    <div className="text">
                        <h4>+25</h4>
                        <h4>MÉTODOS DE PAGO</h4>
                    </div>
                    </div>
                </div>
                <div className="right_data feature_box">
                    <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                    <div className="icon">
                        <img src={img3} alt="image"/>
                    </div>
                    <div className="text">
                        <h4>+4500</h4>
                        <h4>juegos de Casino</h4>
                    </div>
                    </div>
                    <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                    <div className="icon">
                        <img src={img4} alt="image" />
                    </div>
                    <div className="text">
                        <h4>LOS MEJORES PROVEDORES DE CASINO EN VIVO</h4>
                    </div>
                    </div>
                </div>
                <div className="feature_img" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                    <img src={img5} alt="image" />
                </div>
                </div>
            </div>
        </section>}
    </>
  )
}

export default Main