import { Link } from 'react-router-dom'
import React from 'react'
import frame from '../../../assets/images/about-frame.png'
import screen from '../../../assets/images/about-screen.png'
import img1 from '../../../assets/images/download.png'
import img2 from '../../../assets/images/followers.png'
import img3 from '../../../assets/images/reviews.png'
import img4 from '../../../assets/images/countries.png'

const Main = ({video , dark}) => {
  return (
    <>
        <section className="row_am about_app_section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="about_img" data-aos="fade-in" data-aos-duration="1500">
                        <div className="frame_img">
                            <img className="moving_position_animatin" src={frame} alt="image"/>
                        </div>
                        <div className="screen_img">
                            <img className="moving_animation" src={screen} alt="image" />
                        </div>
                        </div>
                    </div>                   
                    <div className="col-lg-6">
                        <div className="about_text">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                            <h2>Miles de juegos <span>de casino integrados</span></h2>
                            <p>
                            Con nuestra amplia selección de más de 4500 juegos de casino, nunca se agotarán las opciones para tus jugadores. 
                            Ofrecemos una experiencia de juego emocionante y diversa, con juegos de mesa clásicos como blackjack y ruleta, 
                            así como una gran variedad de tragamonedas y juegos de video póker.
                            </p>
                        </div>
                            <Link to="/contact" className="btn puprple_btn" data-aos="fade-in" data-aos-duration="1500">DESCUBRE NUESTRA SELLECCIÓN DE JUEGOS</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    </>
  )
}

export default Main